import React from 'react';

const EmptyProfile = () => {
    return (
        <>
            <a>Ваш профиль еще не подтвержден. Обратитесь к Эльке для активации профиля</a>
        </>
    );
};

export default EmptyProfile;