import React, {useEffect, useState} from 'react';
import {Button, Card, Checkbox, Divider, Form, Input, Modal, Popover, Select} from 'antd';
import BackServer from "../../api/BackServer";
import { message, Space } from 'antd';
import {useNavigate} from "react-router-dom";
import LoginStore from "../../Store/LoginStore";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import {RiLockPasswordFill} from "react-icons/ri";
import {FaCircleUser} from "react-icons/fa6";

const LoginForm = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [modal2Open, setModal2Open] = useState(false);
    const [regions, setRegions] = useState([]);
    let mbs=15
    const success = () => {

    };
    const onFinish = async (values) => {
        const response = await BackServer.login(values).then(u => {
            if (u.statusCode==200){
                window.localStorage.setItem('token', u.data.token)
                LoginStore.setAutotized(true)
                LoginStore.setUserDetails(u.data)
                window.location.reload()
            }
        }).catch(u => {
            messageApi.open({
                type: 'error',
                content: u.response.data.status,
            });

            })
    };

    const getRegions = async () => {

        const response = await BackServer.getRegions().then(u => {
            if (u.statusCode==200){
                // messageApi.open({
                //     type: 'success',
                //     content: u.status,
                // });
                setRegions(u.data)
            }
        }).catch(u => {
            messageApi.open({
                type: 'error',
                content: u.response.data.status,
            });
        })
    };
    const onRegFinish = async (values) => {
        const response = await BackServer.register(values).then(u => {
            if (u.statusCode==201){
                messageApi.open({
                    type: 'success',
                    content: u.status,
                });
                window.localStorage.setItem('token', u.data.token)
                LoginStore.setAutotized(true)
                LoginStore.setUserDetails(u.data)
                window.location.reload()
            }
        }).catch(u => {
            console.log(u.response.data.status)
            messageApi.open({
                type: 'error',
                content: u.response.data.status,
            });

        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        getRegions()
    }, [])

    return (
        <>
        {contextHolder}
        <div className="parent">
                <Card
                    title="Вход"
                    className={"block"}
                    bordered={false}
                    style={{
                        width: 400,
                        backgroundColor:"cornsilk"
                    }}
                >
                    <Form
                        name="basic"
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Логин"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите имя пользователя',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Пароль"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите пароль',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            name="remember"
                            valuePropName="checked"
                            // wrapperCol={{
                            //     offset: 8,
                            //     // span: 16,
                            // }}
                        >
                            <Checkbox>Запомнить меня</Checkbox>
                        </Form.Item>

                        <Form.Item
                            // wrapperCol={{
                            //     offset: 8,
                            //     // span: 16,
                            // }}
                        >
                            <Button block type="primary" htmlType="submit">
                                Вход
                            </Button>
                        </Form.Item>

                    </Form>
                    <Divider>или</Divider>
                    <Button block type="primary" onClick={setModal2Open}>
                        Регистрация
                    </Button>


                </Card>

        </div>
            <Modal
                title={ "Регистрация нового пользователя"}
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                footer={[]}
                // footer={[
                //     <Button danger key="back" onClick={() => setModal2Open(false)}>
                //         Закрыть
                //     </Button>,
                //     <Button key="submit" type="primary"  onClick={modal2Open}>
                //         ✅ Сохранить
                //     </Button>,
                //
                // ]}
            >
                <Form
                    name="basicx"
                    style={{
                        maxWidth: 500,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onRegFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label={<FaCircleUser />}
                        name="username"
                        style={{
                            marginBottom: mbs,
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Введите имя пользователя',
                            },
                        ]}
                    >
                        <Input placeholder={"Имя пользователя"} />
                    </Form.Item>

                    <Form.Item
                        style={{
                            marginBottom: mbs,
                        }}
                        label={<RiLockPasswordFill />}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Введите пароль',
                            },
                        ]}
                    >
                        <Input.Password placeholder={"Пароль"} />
                    </Form.Item>
                    <Form.Item
                        style={{
                            marginBottom: mbs,
                        }}
                        name="tlgName"
                        label= {<a> <FaTelegramPlane /></a>}
                        rules={[
                            {
                                required: true,
                                message: 'Введите имя пользователя Telegram',
                            },
                        ]}
                    >
                        {/*<Input />*/}
                        <Input placeholder={"Telegram username"} addonBefore="@" />
                    </Form.Item>

                    <Form.Item
                        style={{
                            marginBottom: mbs,
                        }}
                        name="telNum"
                        label= {<BsFillTelephoneForwardFill />}
                        rules={[
                            {
                                required: true,
                                message: 'Введите номер телефона',
                            },
                        ]}
                    >
                        {/*<Input />*/}
                        <Input placeholder={"9151234567"} addonBefore="+7" />
                    </Form.Item>

                    <Form.Item
                        style={{
                            marginBottom: mbs,
                        }}
                        name="callSign"
                        label= "Позывной"
                        rules={[
                            {
                                required: true,
                                message: 'Введите позывной',
                            },
                        ]}
                    >
                        {/*<Input />*/}
                        <Input placeholder={"Позывной"}/>
                    </Form.Item>
                    <Form.Item
                        style={{
                            marginBottom: mbs,
                        }}
                        name="fio"
                        label= "ФИО"
                        rules={[
                            {
                                required: true,
                                message: 'Введите ФИО',
                            },
                        ]}
                    >
                        <Input placeholder={"Фамилия Имя Отчество"}/>
                    </Form.Item>

                    <Form.Item
                        style={{
                            marginBottom: mbs,
                        }}
                        name="sfera"
                        label= "Направление"
                        rules={[
                            {
                                required: true,
                                message: 'Выберите направление в отряде',
                            },
                        ]}
                    >
                        <Select>
                            <Select.Option value="Регистраторы">Регистраторы</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        style={{
                            marginBottom: mbs,
                        }}
                        name="region"
                        label= "Регион"
                        rules={[
                            {
                                required: true,
                                message: 'Нужно выбрать регион из списка',
                            },
                        ]}
                    >
                        <Select>
                            {regions?.map((c) => (
                                <>
                                    <Select.Option value={c}>{c}</Select.Option>
                                </>
                            ))}

                            <Select.Option value="dem2o">Dem2o</Select.Option>
                        </Select>

                    </Form.Item>

                    <Form.Item
                        style={{
                            marginBottom: mbs,
                        }}
                    >

                        <Button block type="primary" htmlType="submit">
                            Зарегистрироваться
                        </Button>
                    </Form.Item>
                </Form>


            </Modal>
        </>
    );
};

export default LoginForm;