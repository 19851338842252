import React from "react";
import axios from "axios";

export const ERRORSvg = () => (
    <svg width="50px" height="50px" viewBox="-17.52 -17.52 59.04 59.04" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ca1212"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.048"></g><g id="SVGRepo_iconCarrier"> <g clip-path="url(#clip0_429_11171)"> <path d="M2 14.5C2 16.9853 4.01472 19 6.5 19H18.5C20.433 19 22 17.433 22 15.5C22 13.7367 20.6961 12.278 18.9999 12.0354C19.009 10.2322 18.3256 8.4261 16.9497 7.05025C14.2161 4.31658 9.78392 4.31658 7.05025 7.05025C6.15091 7.9496 5.54744 9.03278 5.23984 10.1788C3.36791 10.7238 2 12.4522 2 14.5Z" stroke="#ff0000" stroke-width="1.7759999999999998" stroke-linejoin="round"></path> <rect x="12" y="15.5" width="0.01" height="0.01" stroke="#ff0000" stroke-width="1.7759999999999998" stroke-linejoin="round"></rect> <path d="M12.0002 12L12.0002 9" stroke="#ff0000" stroke-width="1.7759999999999998" stroke-linecap="round" stroke-linejoin="round"></path> </g> <defs> <clipPath id="clip0_429_11171"> <rect width="24" height="24" fill="white"></rect> </clipPath> </defs> </g></svg>)


//COMPOSE_ВНЕШНЯЯ СЕТЬ==================ДЛЯ БИЛДАААА====================
// export const baseUrl_back = 'http://192.168.1.50:21030'
// export const baseUrl_new = 'http://192.168.1.50:21030'

export const baseUrl_back = 'https://api.lizaalert.rezus.pro'
export const baseUrl_new = 'https://api.lizaalert.rezus.pro'

//Debug
// export const baseUrl_new =  'http://127.0.0.1:8000'
// export const baseUrl_back =  'http://127.0.0.1:8000'