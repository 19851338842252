import {makeAutoObservable} from "mobx";

class DataStore {
    Autorized = false
    regionchats=[]

    constructor() {
        makeAutoObservable(this)
    }


    setRegionChats(value) {
        this.regionchats = value
    }

}

export default new DataStore()