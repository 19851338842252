import axios from "axios";
import {baseUrl_new} from "./BaseUrl";

export default class BackServer {



    static async login(logindata1) {
        const response = await axios.post(`${baseUrl_new}/v1/user/login`,
            logindata1
        )
        return response.data
    }

    static async addregion(regiondatas) {
        const response = await axios.post(`${baseUrl_new}/v1/regions/addregion`,
            regiondatas
        )
        return response.data
    }

    static async register(logindata1) {
        const response = await axios.post(`${baseUrl_new}/v1/user`,
            logindata1
        )
        return response.data
    }

    static async getRoles(token) {
        const response = await axios.get(`${baseUrl_new}/v1/user/loginbytoken?Token=`+token)
        return response.data
    }

    getRegionChats

    static async getRegionsChats() {
        const response = await axios.get(`${baseUrl_new}/v1/regions/regionchats`)
        return response.data
    }
    static async delRegionsChats(ID) {
        const response = await axios.delete(`${baseUrl_new}/v1/regions/regionchats?ID=`+ID)
        return response.data
    }
    static async getRegions() {
        const response = await axios.get(`${baseUrl_new}/v1/regions`)
        return response.data
    }
    static async get_Good_Сount_by_Warehouses(SKU) {
        // const response = await axios.get(`${baseUrl_back}categories`)
        const response = await axios.get(`${baseUrl_new}/v1/product/getStocksBySKU?SKU=`+ SKU)
        return response.data
    }
    static async setGoodsPresent(goodsList) {
        const response = await axios.post(`${baseUrl_new}/v1/product/setGoodsPresent`, {
            goodsList: goodsList
        })
        return response.data
    }

}