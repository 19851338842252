import {makeAutoObservable} from "mobx";

class LoginStore {
    Autorized = false
    token="temp"
    itemz=[]
    UserDetails={
        "_id":"1",
    "callSign":"1",
    "confirmation":false,
    "fio":"1",
    "password":"***",
    "region":"1",
    "roles":["temp"],
    "sfera":"",
    "telNum":"",
    "tlgName":"",
    "token":"temp",
    "username":"1",
    }

    constructor() {
        makeAutoObservable(this)
    }
    setAutotized(value) {
        this.Autorized = value
    }

    setToken(value) {
        this.token = value
    }

    setUserDetails(value) {
        this.UserDetails = value
    }

}

export default new LoginStore()