"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mergeLocale = require("../utils/merge-locale");
var _base = require("./base");
const typeTemplate = '${label} ${type} типі емес';
const ruRUS = (0, _mergeLocale.mergeLocale)(_base.base, {
  locale: 'ru-RU',
  common: {
    confirm: 'Сохранить',
    cancel: 'Отмена',
    loading: 'Загрузка'
  },
  Calendar: {
    markItems: ['Дү', 'Се', 'Сә', 'Бе', 'Жұ', 'Се', 'Же'],
    renderYearAndMonth: (year, month) => `${year}жыл${month}-ші ай`
  },
  Cascader: {
    placeholder: 'Таңдаңыз'
  },
  Dialog: {
    ok: 'Мен түсіндім'
  },
  ErrorBlock: {
    default: {
      title: 'Азы наулақ мәселелерге тап болдық',
      description: 'Кейінірек көріңіз'
    },
    busy: {
      title: 'Алда кептеліс',
      description: 'Жаңартып көріңіз'
    },
    disconnected: {
      title: 'Желі бос емес',
      description: 'Қалпына кетіру үшін түртіңіз'
    },
    empty: {
      title: 'Сізге қажетті нәрсе табылмады',
      description: 'Басқа нәрсені іздеп көріңіз'
    }
  },
  Form: {
    required: 'Міндетті',
    optional: 'Селективті',
    defaultValidateMessages: {
      default: '${label} өрісті тексерудегі қате',
      required: '${label} енгізіңіз',
      enum: '${label} [${enum}] мүшесі болуы керек',
      whitespace: '${label} бос болмау керек',
      date: {
        format: '${label} жарамсыз уақыт форматы',
        parse: '${label} уақытқа түрлендірілмейді',
        invalid: '${label} жарамсыз уақыт'
      },
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate
      },
      string: {
        len: '${label} ${len} таңбадан тұруы керек',
        min: '${label} кемінде ${min} таңба болуы керек',
        max: '${label} ең көбінде ${max} таңба болуы керек',
        range: '${label} ұзындығы ${min}-${max} таңба аралығында болуы керек'
      },
      number: {
        len: '${label} және ${len} тең болуы керек',
        min: '${label} минималды мәні ${min}',
        max: '${label} максималды мәні ${max}',
        range: '${label} ${min}-${max} аралығында болуы керек'
      },
      array: {
        len: '${len} ${label} болуы керек',
        min: 'Кемінде ${min} ${label}',
        max: 'Ең көп ${max} ${label}',
        range: '${label} саны ${min}-${max} арасында болуы керек'
      },
      pattern: {
        mismatch: '${label} ${pattern} үлгісіне сәйкес келмейді'
      }
    }
  },
  ImageUploader: {
    uploading: 'Жүктелуде...',
    upload: 'Жүктеу'
  },
  InfiniteScroll: {
    noMore: 'Осыдан артық жоқ',
    failedToLoad: 'Жүктеу сәтсіз болды',
    retry: 'Қайта жүктеу'
  },
  Mask: {
    name: 'Маска қабаты'
  },
  Modal: {
    ok: 'Мен түсіндім'
  },
  PullToRefresh: {
    pulling: 'Төмен тартып жаңарту',
    canRelease: 'Босатып бірден жаңарту',
    complete: 'Сәтті жаңартылды'
  },
  Switch: {
    name: 'Сөндіргіш'
  }
});
const ruRU = {
  locale: 'en',
  common: {
    confirm: 'ОК',
    cancel: 'Отмена',
    loading: 'Загрузка',
    close: 'Закрыть'
  },
  Calendar: {
    markItems: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    renderYearAndMonth: (year, month) => `${year}/${month}`
  },
  Cascader: {
    placeholder: 'Выбор'
  },
  Dialog: {
    ok: 'OK'
  },
  DatePicker: {
    tillNow: 'До сих пор'
  },
  ErrorBlock: {
    default: {
      title: 'Упс! Что-то пошло не так',
      description: 'Пожалуйста, подождите минуту и попробуйте еще раз'
    },
    busy: {
      title: 'Oops, not loading',
      description: 'Try to refresh the page'
    },
    disconnected: {
      title: 'Network is busy',
      description: 'Try to refresh the page'
    },
    empty: {
      title: "Hmm, couldn't find that...",
      description: 'Want to try a new search?'
    }
  },
  Form: {
    required: 'Обязательное',
    optional: 'Опциональное',
    defaultValidateMessages: {
      default: 'Field validation error for ${label}',
      required: 'Please enter ${label}',
      enum: '${label} must be one of [${enum}]',
      whitespace: '${label} cannot be a blank character',
      date: {
        format: '${label} date format is invalid',
        parse: '${label} cannot be converted to a date',
        invalid: '${label} is an invalid date'
      },
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate
      },
      string: {
        len: '${label} must be ${len} characters',
        min: '${label} must be at least ${min} characters',
        max: '${label} must be up to ${max} characters',
        range: '${label} must be between ${min}-${max} characters'
      },
      number: {
        len: '${label} must be equal to ${len}',
        min: '${label} must be minimum ${min}',
        max: '${label} must be maximum ${max}',
        range: '${label} must be between ${min}-${max}'
      },
      array: {
        len: 'Must be ${len} ${label}',
        min: 'At least ${min} ${label}',
        max: 'At most ${max} ${label}',
        range: 'The amount of ${label} must be between ${min}-${max}'
      },
      pattern: {
        mismatch: '${label} does not match the pattern ${pattern}'
      }
    }
  },
  ImageUploader: {
    uploading: 'Uploading...',
    upload: 'Upload'
  },
  InfiniteScroll: {
    noMore: 'No more',
    failedToLoad: 'Ошибка загрузки',
    retry: 'Повтор'
  },
  Input: {
    clear: 'очистить'
  },
  Mask: {
    name: 'Mask'
  },
  Modal: {
    ok: 'OK'
  },
  PasscodeInput: {
    name: 'Passcode Input'
  },
  PullToRefresh: {
    pulling: 'Scroll down to refresh',
    canRelease: 'Release to refresh immediately',
    complete: 'Refresh successful'
  },
  SearchBar: {
    name: 'Search Bar'
  },
  Slider: {
    name: 'Slider'
  },
  Stepper: {
    decrease: 'decrease',
    increase: 'increase'
  },
  Switch: {
    name: 'Switch'
  },
  Selector: {
    name: 'Selector'
  }
};
var _default = ruRU;
exports.default = _default;