import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import BackServer from "../../api/BackServer";
import LoginStore from "../../Store/LoginStore";
import {Button, Card, Checkbox, Divider, Form, Input, Modal, Popover, Select, Table} from 'antd';

import { message, Space } from 'antd';
import {useNavigate} from "react-router-dom";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import {RiLockPasswordFill} from "react-icons/ri";
import { MdOutlineDeleteForever } from "react-icons/md";

import {FaCircleUser} from "react-icons/fa6";
import DataStore from "../../Store/DataStore";

const RegistrationRegionsChats = observer((props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [regions, setRegions] = useState([]);
    let mbs=15

    const deleteChat = async (id) => {
        try {
            const response = await BackServer.delRegionsChats(id).then(u => {
                if (u.statusCode==200){
                    // console.log(u.data)
                    messageApi.open({
                        type: 'success',
                        content: u.status,
                    });
                    DataStore.setRegionChats(u.data)
                    // window.localStorage.setItem('token', u.data.token)
                    // LoginStore.setAutotized(true)
                    // LoginStore.setUserDetails(u.data)
                    // window.location.reload()
                }
            })
        } catch (err) {
            console.log("Ошибка")
        }
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const getRegions = async () => {

        const response = await BackServer.getRegions().then(u => {
            if (u.statusCode==200){
                // messageApi.open({
                //     type: 'success',
                //     content: u.status,
                // });
                setRegions(u.data)
            }
        }).catch(u => {
            messageApi.open({
                type: 'error',
                content: u.response.data.status,
            });
        })
    };
    useEffect(() => {
        getRegions()
        getRegionChats()
    }, [])

    const getRegionChats= async () => {
        const response = await BackServer.getRegionsChats().then(u => {
            if (u.statusCode==201){
                messageApi.open({
                    type: 'success',
                    content: u.status,
                });
                // console.log(u)
                DataStore.setRegionChats(u.data)
                // window.localStorage.setItem('token', u.data.token)
                // LoginStore.setAutotized(true)
                // LoginStore.setUserDetails(u.data)
                // window.location.reload()
            }
        }).catch(u => {
            messageApi.open({
                type: 'error',
                content: u.response.data.status,
            });

        })
    };
    const onFinish = async (values) => {
        const response = await BackServer.addregion(values).then(u => {
            if (u.statusCode==201){
                // console.log(u.data)
                messageApi.open({
                    type: 'success',
                    content: u.status,
                });
                DataStore.setRegionChats(u.data)
                // window.localStorage.setItem('token', u.data.token)
                // LoginStore.setAutotized(true)
                // LoginStore.setUserDetails(u.data)
                // window.location.reload()
            }
        }).catch(u => {
            messageApi.open({
                type: 'error',
                content: u.response.data.status,
            });

        })
    };
    const columns = [
        {
            title:   "ID чата",
            dataIndex: 'chat_id',
            key: 'chat_id',
            align:'center',
            width: '40%',
            render: (_, record) => (
                record.chat_id
                // <Checkbox value={record.posting_number} checked={getSelected(record.posting_number)}   onChange={CheckonChange}></Checkbox>
            )
        },
        {
            title: 'Регион',
            dataIndex: 'chatIndexName',
            key: 'chatIndexName',
            align:'center',
            width: '40%',
            // sorter: (a, b) => a.proc_date_str.length - b.proc_date_str.length,
            // sortDirections: ['descend', 'ascend'],
            render: (_, record) => (
                record.chatIndexName
            ),
        },
        {
            title: 'Удалить',
            dataIndex: 'dell',
            key: 'del',
            align:'center',
            width: '20%',
            // sorter: (a, b) => a.proc_date_str.length - b.proc_date_str.length,
            // sortDirections: ['descend', 'ascend'],
            render: (_, record) => (

                <Button value="small" onClick={() => deleteChat(record.chatIndexName)} icon={<MdOutlineDeleteForever />} color="cyan"></Button>
            ),
        }

        ]
    return (
        <>
            {/*{contextHolder}*/}
            <div className="parenst">
                <Card
                    title="Добавить чат"
                    className={"blockX"}
                    bordered={false}
                    style={{
                        width: 400,
                        backgroundColor:"cornsilk"
                    }}
                >
                    <Form
                        name="basic"
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="ID чата"
                            name="chat_id"

                            rules={[
                                {
                                    required: true,
                                    message: 'Введите ID чата',
                                },
                            ]}
                        >
                            <Input placeholder={"-100XXXXXXXXXX"}  />
                        </Form.Item>
                        <Form.Item
                            style={{
                                marginBottom: mbs,
                            }}
                            name="chatIndexName"
                            label= "Регион"
                            rules={[
                                {
                                    required: true,
                                    message: 'Нужно выбрать регион из списка',
                                },
                            ]}
                        >
                            <Select>
                                {regions?.map((c) => (
                                    <>
                                        <Select.Option value={c}>{c}</Select.Option>
                                    </>
                                ))}

                                <Select.Option value="dem2o">Dem2o</Select.Option>
                            </Select>

                        </Form.Item>

                        <Form.Item
                            // wrapperCol={{
                            //     offset: 8,
                            //     // span: 16,
                            // }}
                        >
                            <Button block type="primary" htmlType="submit">
                                Добавить
                            </Button>
                        </Form.Item>

                    </Form>
                    </Card>
            </div>
            {/*{DataStore.regionchats?.map((c) => (*/}
            {/*    <>*/}
            {/*        <a>{c.chatIndexName}</a>*/}
            {/*    </>*/}
            {/*))}*/}
            <Table columns={columns} dataSource={DataStore.regionchats}>   pagination={{ defaultPageSize: 150, showSizeChanger: true, pageSizeOptions: ['150','200','500','2000']}}
                size={"small"}</Table>
            </>
    );
})

export default RegistrationRegionsChats;