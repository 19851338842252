import React from 'react';
import {observer} from "mobx-react";
import CarStore from "../../Store/car-store";


const Ent = observer((props) => {
    return (
        <div>
            {CarStore.currentSpeed}
            dddddddddddddddddddddd
        </div>
    );
})

export default Ent;