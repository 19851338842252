import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import ruRU from './locales/ru-RU'
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppRoute from "./Components/AppRoute";
import {Provider} from "mobx-react";
import CarStore from "./Store/car-store";
import {ConfigProvider} from "antd";
const root = ReactDOM.createRoot(document.getElementById('root'));

const stores = {
    CarStore
};
// gg
root.render(
    <Provider {...stores}>
        <React.StrictMode>
            <ConfigProvider
                locale={ruRU}
                theme={{
                    components: {
                        Picker: {
                            colorPrimary: '#00b96b',
                        },
                    },
                }}
            >
                <BrowserRouter>
                    <AppRoute/>
                </BrowserRouter>
            </ConfigProvider>
        </React.StrictMode>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
