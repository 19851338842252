import {makeAutoObservable} from "mobx";
// import ZakazStore from "./zakaz-store";
// import TovarStore from "./tovar-store";

class MenuState {
    currentMenu  =window.localStorage.getItem('MenuCurrent')
    // currentMenuKey  =window.localStorage.getItem('MenuCurrentKey')
    currentMenuKey  =window.localStorage.getItem('mainPage')

    constructor() {
        makeAutoObservable(this)
    }

    setcurrentMenu(value) {
        this.currentMenu = value
        window.localStorage.setItem('MenuCurrent',value)
        // ZakazStore.refreshdata()
        // TovarStore.refreshdata()
    }
    setcurrentKEY(value) {
        this.currentMenuKey = value
        window.localStorage.setItem('MenuCurrentKey',value)
        // ZakazStore.refreshdata()
    }
}

export default new MenuState()