import {makeAutoObservable} from "mobx";

class CarStore {
    currentSpeed = 0;
    maxSpeed = 0;
    mark = ""

    constructor() {
        makeAutoObservable(this)
    }

    setCurrentSpeed(value) {
        this.currentSpeed = value
    }
    setMaxSpeed(value) {
        this.maxSpeed = value
    }
    setMark(value) {
        this.mark = value
    }

}

export default new CarStore()