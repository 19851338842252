import React, {useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";
import Ent from "./EmptyComp/Ent";
import LoginForm from "./Autorization/LoginForm";
import LoginStore from "../Store/LoginStore";
import loginStore from "../Store/LoginStore";
import MainWindow from "./MainWindow/MainWindow";
import BackServer from "../api/BackServer";
import {message} from "antd";
import {observer} from "mobx-react";
import {MailOutlined} from "@ant-design/icons";
import {FaUserLock} from "react-icons/fa";
import {HiChatBubbleLeftRight} from "react-icons/hi2";



const AppRoute = observer((props) => {
    const [userToken, setuserToken]=useState("temp");
    const [refr, setRefr]=useState("temp");
    const [messageApi, contextHolder] = message.useMessage();
    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }
    const items = [
        // getItem('Главная', 'mainPage', <MailOutlined/>, ),
        // getItem('Чаты регионов', 'regionChats', <MailOutlined/>, ),
        // getItem('Главная2', 'main2', <MailOutlined/>, ),
        // getItem('Главная3', 'main3', <MailOutlined/>, )
    ];
    const onRegFinish = async (token) => {
        const response = await BackServer.getRoles(token).then(u => {
            if (u.statusCode==200){
                messageApi.open({
                    type: 'success',
                    content: u.status,
                });
                window.localStorage.setItem('token', u.data.token)
                LoginStore.setAutotized(true)
                LoginStore.setUserDetails(u.data)
                // console.log("sssssssssssssddddddddddd")
                if (u.data.roles.indexOf( 'temp' ) != -1 )
                {
                    loginStore.itemz.push(getItem(u.data.callSign, 'EmptyProfile',<FaUserLock />, ),)
                }
                if (u.data.roles.indexOf( "all_region_chats" ) != -1 )
                {
                    // console.log("ssssssss234567d")
                    loginStore.itemz.push(getItem('Чаты регионов', 'regionChats', <HiChatBubbleLeftRight />, ),)
                }

                loginStore.setToken(u.data.token)
                // window.location.reload()
                console.log(u)
                console.log("log")
            }
        }).catch(u => {
            console.log(u.response.data.status)
            messageApi.open({
                type: 'error',
                content: u.response.data.status,
            });
            loginStore.setAutotized(false)
            console.log("UNlog")

        })
    };
    useEffect(() => {
     let xuserToken =window.localStorage.getItem('token')
        onRegFinish(xuserToken)
        // alert(userToken)
 }, [])


    if (LoginStore.Autorized==false)
    {

        return <LoginForm/>
    }
    return (
        <>

            <Routes>
                <Route path="/" element={<MainWindow/>}/>

                {/*<Route path="/" element={<NewMainPage/>}/>*/}
                {/*<Route path="/bar" element={<AllBarCodeNewPage></AllBarCodeNewPage>}/>*/}
                {/*<Route path="/2" element={<Car/>}/>*/}
                {/*<Route path="/newx" element={<Orders2023/>}/>*/}
                {/*<Route path="/new" element={<LayOut2023/>}/>*/}

                {/*<Route path="/catalog" element={*/}
                {/*    <Catalog callbackX={callup} style={Cont_style}/>}>*/}
                {/*    <Route path=":message_id" element={<Catalog/>} />*/}
                {/*</Route>*/}

            </Routes></>
    );
});

export default AppRoute;