import React, {useEffect, useState} from 'react';
import {Button, Card, Checkbox, Form, Input, Layout, Menu} from 'antd';
import BackServer from "../../api/BackServer";
import { message, Space } from 'antd';
import {useNavigate} from "react-router-dom";
import LoginStore from "../../Store/LoginStore";
import {observer} from "mobx-react";
import Sider from "antd/es/layout/Sider";
import {Content, Header} from "antd/es/layout/layout";
import{
    MenuFoldOutlined,
        UploadOutlined,
        UserOutlined,
        VideoCameraOutlined,
        MenuUnfoldOutlined,
        MailOutlined,
        ShoppingCartOutlined,
        AppstoreOutlined,
        AppstoreAddOutlined,
        ApiOutlined,
        RightOutlined,
        InfoCircleOutlined,
        ArrowRightOutlined,
        ExclamationCircleOutlined,
        CloseOutlined
} from '@ant-design/icons';
import MenuState from "../../Store/MenuState";
import RegistrationRegionsChats from "../RegistrationRegionsChats/RegistrationRegionsChats";
import loginStore from "../../Store/LoginStore";
import EmptyProfile from "../EmptyProfile/EmptyProfile";

const MainWindow = observer((props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [goods, setGoods] = useState([])
    const [ozonSpin, setozonSpin] = useState(false)
    const [countOzon, setCountOzon] = useState(-1)
    const [currentPage, setcurrentPage] = useState('mainPage');
    // const [currentPage, setcurrentPage] = useState(<AllOrders tip={MenuState.currentMenu}></AllOrders>)


    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }
    const items = props.itemz
console.log("ssd444444")
console.log(props)




    const onClick = (e) => {
        console.log(e.key)
        if (e.key=='regionChats'){
            console.log("00000000000000000")
            MenuState.setcurrentKEY("regionChats");
            setcurrentPage(<RegistrationRegionsChats/>)
        }
        if (e.key=='EmptyProfile'){
            MenuState.setcurrentKEY("EmptyProfile");
            setcurrentPage(<EmptyProfile/>)
        }

        // else {
        //     setcurrentPage(e.key)
        //     MenuState.setcurrentKEY(e.key);
        // }
    };
    useEffect(() => {

    }, [])
    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="demo-logo-vertical" />
                <Menu
                    className={"sss"}
                    theme="dark"
                    onClick={onClick}
                    mode="inline"
                    defaultSelectedKeys={[MenuState.currentMenuKey]}
                    selectedKeys={MenuState.currentMenuKey}
                    defaultOpenKeys={['orders','goods','info']}
                    items={loginStore.itemz}
                />
            </Sider>
            <Layout>

                <Header
                    style={{
                        padding: 0,
                    }}
                >
                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: '90vh',
                    }}
                >
                    {currentPage}
                </Content>
            </Layout>
        </Layout>
    );
});

export default MainWindow;